import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import baseURL from '../constants/baseURL';
import UserBox from './user-box.component';

//Mui
import {DataGrid} from '@mui/x-data-grid'

function UserList(props){

        const [users, setUsers] = useState([])

        const columns = [
                {field: 'id', headerName: 'ID'},
                {
                        field: 'username',
                        headerName: "brukernavn",
                        editable: true
                },
                {
                        field: 'email',
                        headerName: "brukernavn",
                        editable: true
                },
                {
                        field: 'emailVerified',
                        headerName: "verifisert epost?",
                        editable: true
                },
                {
                        field: 'kontigent',
                        headerName: "kontigent?",
                        editable: true
                }
        ]

        useEffect(() => {
                axios.get(baseURL+'/users')
                        .then((res) => {
                                console.log(res.data)
                                setUsers(res.data.map(user => <UserBox key={user._id} data={user}/>))
                        }).catch((err) => {
                                console.log('noe gikk galt', err)
                        })
        }, [])
        return(
                <div>
                        {users}
                </div>
        )
}

export default UserList