import { Card, CardContent, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import './game-box.component.css'
import {format, parseISO} from 'date-fns'


function GameBox(props){
        const navigate = useNavigate()

        const gotoGame = () => {
                console.log(props.data)
                navigate('/game/'+props.data._id)
        }

        return(
                <Card onClick={gotoGame}>
                        <CardContent>
                                <Typography variant="h5">
                                        {props.data.title}
                                </Typography>
                                <Typography variant="h6">
                                        DM: {props.data.arrangerUsername}
                                </Typography>
                                <Typography>
                                        Tid: {format(parseISO(props.data.startTime), 'dd/MM/yy h:mm')}
                                </Typography>
                        </CardContent>
                </Card>
        )
}

export default GameBox