import React, { Component } from 'react';
import { useEffect} from "react"

function LogoutPage(props){

        useEffect(() => {
                //window.location.reload(false)
        }, [])

        return(
                <h1>Utlogget</h1>
        )
}
export default LogoutPage