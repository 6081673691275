import React, { Component } from 'react';
import axios from 'axios';
import baseURL from '../constants/baseURL';
import aguibLogo from '../images/aguiblogo.jpg'

export default class HomePage extends Component{
        render(){
                /*if(localStorage.getItem("accessToken")){
                        axios.post(baseURL+'/users/logged_in', {}, {
                                headers: {
                                        'Authorization':'Bearer ' + localStorage.getItem("accessToken")
                                }
                        })
                        .then((res) => {
                                if(res.status === 400){
                                        console.log("Failed to verify that you are logged in")
                                        this.logoutState()
                                }
                                console.log("Logged in as:", res.data.userId, "Role:", res.data.role)
                                return(
                                        <h1>Logget inn som {res.data.userName}</h1>
                                )
                        }).catch((err) => {
                                console.log(err)
                                return(
                                        <h1>Test Home Page</h1>
                                )
                        })
                }else{*/
                        return(
                                <div>
                                        <h1>Welcome to the guild, here you will find information about our future events and games</h1>
                                        <img src={aguibLogo} alt="logo"/>
                                </div>
                        )
                //}
        }
}