import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

function LogButton(props) {

        const navigate = useNavigate()
        const [onClick, setOnclick] = useState(null)
        const [text, setText] = useState('')

        const gotoLogin = () => {
                navigate('/login')
                console.log("går til loginpage")
        }

        return(
                <button onClick={props.loggedIn? props.handleLogout : gotoLogin}>
                        {props.loggedIn? "Logg ut" : "Logg inn"}
                </button>
        )
}

export default LogButton