import React, { Component, useState } from 'react';
import axios from 'axios';
import "./login-page.component.css"
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../constants/baseURL'
import { useNavigate } from 'react-router-dom';

const toastOptions = {
  position: 'top-center',
  autoClose: false,
  closeOnClick: true
}


function LoginPage(props){

  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  /*UNSAFE_componentWillMount(){
        this.onChangeLoginId = this.onChangeLoginId(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
  }*/

  const onChangeUsername = (e) => {
    setUsername(e.target.value)
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    axios.post(baseURL+'/users/login', {"loginName": username, "password": password})
    .then((res) => {
      props.handleLogin(res.data.accessToken)
      toast("Login lykkes")
    })
    .catch((err) => {
      console.log(err)
      toast("Feil login info")
    })    
    //window.location = '/'
  }

  return (
    <div className="login-box">
    <h3>Login</h3>
    <form onSubmit={onSubmit}>
    <div className="form-group"> 
        <input 
            required
            type="text" 
            placeholder='Brukernavn'
            className="form-control"
            value={username}
            onChange={onChangeUsername}
            />
      </div>
      <div className="form-group">
        <input 
            required
            type="password" 
            placeholder='Passord'
            className="form-control"
            onChange={onChangePassword}
            />
      </div>
      <div className="form-group">
        <input type="submit" value="Logg Inn" className="btn btn-primary" />
      </div>
      <ToastContainer position='top-center'/>
    </form>
  </div>
  )
}

export default LoginPage