import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import CreateUser from './components/create-user.component';
import LoginPage from "./components/login-page.component"
import LogoutPage from "./components/logout-page.component"
import HomePage from "./components/home-page.component"
import Navbar from "./components/navbar.component"

ReactDOM.render(
    //Redirect to heroku website
    /*
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>*/
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
    ,
    document.getElementById('root')
);
