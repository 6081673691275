import React, { useState } from 'react';
import './create-game.component.css'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../constants/baseURL'
import axios from 'axios';

function CreateGame(props){

        const [title, setTitle] = useState('')
        const [description, setDescription] = useState('')
        const [startTime, setStartTime] = useState(null)
        const [endTime, setEndTime] = useState(null)
        const [maxParticipants, setMaxParticipants] = useState(0)
        const [location, setLocation] = useState('')
        const [level, setLevel] = useState(0)
        
        const onSubmit = (e) => {
                const game = {
                        title: title,
                        description: description,
                        startTime: startTime,
                        endTime: endTime,
                        maxParticipants: maxParticipants,
                        location: location,
                        level: level
                }
                e.preventDefault()
                axios.post(baseURL+'/games/create', game
                ).then(() => {
                        toast("Game lagret!")
                }).catch((err) => {
                        toast("Noe gikk galt under lagring av game!")
                        console.log(err)
                })
        }

        return(
                <div className="create-game-box">
                        <h3>Lag Game</h3>
                        <form onSubmit={onSubmit}>
                                <div className="form-group">
                                        <label>Tittel</label>
                                        <input 
                                        required
                                        type="text" 
                                        placeholder='Tittel'
                                        className="form-control"
                                        onChange={e => setTitle(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Beskrivelse</label>
                                        <input 
                                        required
                                        type="text" 
                                        placeholder='Beskrivelse'
                                        className="form-control"
                                        onChange={e => setDescription(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Start Tid:</label>
                                        <input 
                                        required
                                        type="datetime-local" 
                                        className="form-control"
                                        onChange={e => setStartTime(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Slutt Tid:</label>
                                        <input 
                                        required
                                        type="datetime-local" 
                                        className="form-control"
                                        onChange={e => setEndTime(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Maks Deltakere</label>
                                        <input 
                                        required
                                        type="number" 
                                        placeholder='Maks deltakere'
                                        className="form-control"
                                        onChange={e => setMaxParticipants(e.target.value)}
                                        />
                                </div>
                        
                                <div className="form-group">
                                        <label>Sted</label>
                                        <input 
                                        required
                                        type="text" 
                                        placeholder='Sted'
                                        className="form-control"
                                        onChange={e => setLocation(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Minimum/Anbefalt level</label>
                                        <input 
                                        required
                                        type="number" 
                                        placeholder='Minimum/Anbefalt level'
                                        className="form-control"
                                        onChange={e => setLevel(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <input type="submit" value="Lag Game" className="btn btn-primary" />
                                </div>
                                <ToastContainer position='top-center'/>
                        </form>
                </div>
        )
}

export default CreateGame