import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import baseURL from '../constants/baseURL';
import GameBox from './game-box.component';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

function GameList(props){

        const [games, setGames] = useState([])

        const Item = styled(Paper)(({ theme }) => ({
                backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                ...theme.typography.body2,
                padding: theme.spacing(1),
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }));
        //const [games, setGames] = useState([])

        useEffect(() => {
                let hook = ''
                if(props.dmId){
                        hook = '/' + props.dmId
                }
                console.log(baseURL+'/games'+hook)
                axios.get(baseURL+'/games'+hook)
                        .then((res) => {
                                console.log(res.data)
                                setGames(res.data.map(game => 
                                        <Item>
                                                <GameBox key={game._id} data={game}/>
                                        </Item>
                                ))
                        }).catch((err) => {
                                console.log('noe gikk galt', err)
                        })
        }, [])
        return(
                <div>
                        <Stack>
                                {games}
                        </Stack>
                </div>
        )
}

export default GameList