import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import baseURL from "../constants/baseURL"
import { toast, ToastContainer } from 'react-toastify';

import {Typography, Select, MenuItem, InputLabel, Button, TextField} from '@material-ui/core'
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

function UserEdit(props){
        const params = useParams()
        const [username, setUsername] = useState('')
        const [email, setEmail] = useState('')
        const [kontigent, setKontigent] = useState(true)
        const [emailVerified, setEmailVerified] = useState(false)
        const [role, setRole] = useState(0)

        useEffect(() => {
                axios.get(baseURL+'/user/'+params.id)
                        .then(res => {
                                setUsername(res.data.username)
                                setEmail(res.data.email)
                                setKontigent(res.data.kontigent)
                                setEmailVerified(res.data.emailVerified)
                                console.log("role: " + res.data.role)
                                setRole(res.data.role)
                        }).catch(err => {
                                //Do something when user can't be found, maybe redirect to user list
                                console.log(err)
                                //navigate('/games/list')
                        })
        }, [])

        const handleSubmit = (e) => {
                const user = {
                        username: username,
                        email: email,
                        kontigent: kontigent,
                        emailVerified: emailVerified,
                        role: role
                }
                e.preventDefault()
                axios.put(baseURL+'/user/'+params.id, user
                ).then(() => {
                        toast("Endringer lagret!")
                        //navigate('/game/'+params.id)
                }).catch((err) => {
                        toast("Noe gikk galt under lagring av bruker!")
                        console.log(err)
                })
                //Save the changes made to the user to database
        }

        const Item = styled(Paper)(({ theme }) => ({
                backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                ...theme.typography.body2,
                padding: theme.spacing(1),
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }));


        return(
                <div>
                        <Stack spacing={2}>
                                <Item>
                                        <TextField id="outlined-basic" label="brukernavn" value={username} onChange={(evt, value) => setUsername(value)} variant="outlined" />
                                </Item>
                                <Item>
                                        <TextField id="outlined-basic" label="epost" value={email} onChange={(evt, value) => setEmail(value)} variant="outlined" />
                                </Item>
                                <Item>
                                        <InputLabel id="demo-simple-select-label">Kontigent</InputLabel>
                                        <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={kontigent}
                                                label="Kontigent"
                                                onChange={e => setKontigent(e.target.value)}
                                        >
                                                <MenuItem value={"true"}>Ja</MenuItem>
                                                <MenuItem value={"false"}>Nei</MenuItem>
                                        </Select>
                                </Item>
                                <Item>
                                        <InputLabel id="demo-simple-select-label">Verifisert epost</InputLabel>
                                        <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={emailVerified}
                                                label="Verifisert epost"
                                                onChange={e => setEmailVerified(e.target.value)}
                                        >
                                        <MenuItem value={"true"}>Ja</MenuItem>
                                        <MenuItem value={"false"}>Nei</MenuItem>
                                        </Select>
                                </Item>
                                <Item>
                                        <InputLabel id="demo-simple-select-label">Rolle</InputLabel>
                                        <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={role}
                                                label="Rolle"
                                                onChange={e => setRole(e.target.value)}
                                        >
                                        <MenuItem value={0}>Gjest</MenuItem>
                                        <MenuItem value={2}>Basic</MenuItem>
                                        <MenuItem value={5}>DM</MenuItem>
                                        <MenuItem value={10}>Admin</MenuItem>
                                        </Select>
                                </Item>
                                <Item>
                                        <Button variant="contained" onClick={handleSubmit}>Lagre</Button>
                                </Item>
                        </Stack>
                        <ToastContainer position='top-center'/>
                </div>
        )
}
export default UserEdit