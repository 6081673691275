import React, {Component, useEffect, useState} from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter, Routes, Route, useNavigate, Navigate, useParams} from "react-router-dom";
import baseURL from './constants/baseURL'
import { toast } from 'react-toastify';

//Mui
import { Typography, AppBar, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar } from '@material-ui/core';
import Theme from "./components/theme"
import { ThemeProvider } from '@material-ui/core';

//Components
import Navbar from "./components/navbar.component"
import LoginPage from "./components/login-page.component"
import EmailConfirmedPage from "./components/email-confirmed-page.component"
import LogoutPage from "./components/logout-page.component"
import HomePage from "./components/home-page.component"
import CreateUser from "./components/create-user.component";
import GameList from "./components/game-list.component";
import EditGame from "./components/edit-game.component";
import CreateGame from "./components/create-game.component";
import DMPanel from './components/dm-panel.component';
import GamePage from './components/game-page.component';

import AdminPanel from './components/admin-panel.component';
import UserList from './components/user-list.component';
import UserEdit from './components/user-edit.component';


function App(props){

        const navigate = useNavigate()
        const params = useParams()
        const [isLoggedIn, setLoggedIn] = useState(false)
        const [userInfo, setUserInfo] = useState(null)

        useEffect(() => {
                window.location.assign("https://abuib-backend.herokuapp.com" + window.location.pathname)
                //loggedIn()
        }, [isLoggedIn])

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("accessToken")

        const loggedIn = () => {
                axios.post(baseURL+'/users/logged_in'
                ).then((res) => {
                        updateLoginState(true, {
                                userId: res.data.userId,
                                username: res.data.username,
                                role: res.data.role
                        })
                        console.log("pålogget med følgende info:", res.data.username, res.data.userId, res.data.role)
                }).catch((err) => {
                        updateLoginState(false, null)
                        if(err.response && err.response.status === 400){
                                console.log('du er ikke pålogget')
                                //Er ikke pålogget
                        }else{
                                console.log('kanskje server feil')
                                //Kanskje server feil
                        }
                })
        }

        /*function logout(){
                localStorage.removeItem("accessToken")
                navigate('/login')
                updateLoginState(false, null)
        }*/

        const handleLogin = (accessToken) => {
                console.log("Handling login")
                localStorage.setItem("accessToken", accessToken)
                loggedIn()
                navigate('/home')
        }

        const handleLogout = () => {
                console.log("Handling logout")
                localStorage.removeItem("accessToken")
                loggedIn()
                navigate('/logout')
        }

        const updateLoginState = (isLoggedIn, userData) => {
                setLoggedIn(isLoggedIn)
                setUserInfo(userData)
        }

        return(
                <ThemeProvider theme={Theme}>
                        <div className="App">
                        <Navbar userInfo={userInfo} handleLogout={handleLogout} loggedIn={isLoggedIn}/>
                        <Routes>
                                <Route path="/" element={<CreateUser/>}/>
                                <Route path="login" element={<LoginPage loggedIn={isLoggedIn} handleLogin={handleLogin}/>}/>
                                <Route path="logout" element={<LogoutPage/>}/>
                                <Route path="home" element={<HomePage/>}/>
                                <Route path="confirmEmail/:accessToken" element={<EmailConfirmedPage/>}/>
                                <Route path="dm-panel" element={<DMPanel/>}/>
                                <Route path="games/list" element={<GameList/>}/>
                                <Route path="games/create" element={<CreateGame/>}/>
                                <Route path="game/:id" element={<GamePage userInfo={userInfo}/>}/>
                                <Route path="game/:id/edit" element={<EditGame userInfo={userInfo}/>}/>
                                <Route path="dm/games" element={userInfo? <GameList dmId={userInfo.userId}/> : <GameList/>}/>

                                <Route path="admin" element={<AdminPanel/>}/>
                                <Route path="admin/users" element={<UserList/>}/>
                                <Route path="admin/user/:id" element={<UserEdit/>}/>
                        </Routes>
                </div>
                </ThemeProvider>
        )
}

export default App
