import {Link} from 'react-router-dom';

function DMPanel(props){
        return(
                <div>
                        <li>
                                <h1>DM Panel</h1>
                                <ul><Link to="/games/create">Lag nytt game</Link></ul>
                                <ul><Link to="/dm/games">Mine games</Link></ul>
                        </li>
                </div>
        )
}

export default DMPanel