import { useNavigate } from "react-router-dom"
import './game-box.component.css'

function UserBox(props){
        const navigate = useNavigate()

        const gotoUser = () => {
                console.log(props.data)
                navigate('/admin/user/'+props.data._id)
        }

        return(
                <div className='user-box' onClick={gotoUser}>
                        <h1>{props.data.username}</h1>
                </div>
        )
}

export default UserBox