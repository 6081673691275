import React, { Component } from 'react';
import axios from 'axios';
import "./create-user.component.css"
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../constants/baseURL'
//import mailHelper from '../helpers/mail_helper'

const toastOptions = {
  position: 'top-center',
  autoClose: false,
  closeOnClick: true
}

const initialState = {
      username: '',
      firstName: '',
      lastName: '',
      studentNr: '',
      email: '',
      birthDate: '1995-01-01',
      password: '',
      confirmPassword: ''
}


export default class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeStudentNr = this.onChangeStudentNr.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = initialState
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    })
  }

  onChangeFirstName(e) {
    this.setState({
      firstName: e.target.value
    })
  }

  onChangeLastName(e) {
    this.setState({
      lastName: e.target.value
    })
  }

  onChangeStudentNr(e) {
    this.setState({
      studentNr: e.target.value
    })
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  onChangeBirthDate(e) {
    //console.log(e.target.selected)
    this.setState({
      birthDate: e.target.value
    })
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault();
    if(!(this.state.password === this.state.confirmPassword)){
      toast("Passordene er ikke like", toastOptions)
      return
    }
    var re = /\S+@\S+\.\S+/; //Regex to validate email
    if (!re.test(this.state.email)){
      toast("Feil format på epost!", toastOptions)
      return
    }
    const user = {
      username: this.state.username,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      studentNr: this.state.studentNr,
      email: this.state.email,
      birthDate: this.state.birthDate,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    }

    /*
      <DatePicker 
              required
              className="form-control"
              dateFormat= 'dd/MM/yyyy'
              selected={this.state.birthDate}
              onChange={(date) => this.onChangeBirthDate(date)}
              />
    */

    console.log(user);

    axios.post(baseURL+'/users/create', user)
      .then((res) => {
        //mailHelper.sendVerification(user.email)
        toast("Bruker registrert!", toastOptions)
        axios.post(baseURL+'/users/sendConfirmEmail', {"id": res.data.id, "username": user.username, "email": user.email})
          .then((res) => {
              toast("Mail sendt")
          }).catch((err) => {
              console.log(err)
              toast("Noe gikk galt under sending av mail")
          })
      }).catch((err) => {
        console.log(err)
        if(err.response && err.response.status === 403){
          toast("Brukernavn, e-post eller student nummer er allerede i bruk!", toastOptions)
        }else{
          toast("Noe gikk galt, kontakt support@aguib.no!", toastOptions)
        }
        
      })
    //window.location = '/'
  }

  render() {
    return (
      <div className="register-box">
      <h3>Registrer Bruker</h3>
      <form onSubmit={this.onSubmit}>
        <div className="form-group"> 
          <input 
              required
              type="text" 
              placeholder='Brukernavn'
              className="form-control"
              value={this.state.username}
              onChange={this.onChangeUsername}
              />
        </div>
        <div className="form-group"> 
          <input
              required
              type="text"
              placeholder='Fornavn'
              className="form-control"
              value={this.state.firstName}
              onChange={this.onChangeFirstName}
              />
        </div>
        <div className="form-group"> 
          <input 
              required
              type="text"
              placeholder='Etternavn'
              className="form-control"
              value={this.state.lastName}
              onChange={this.onChangeLastName}
              />
        </div>
        <div className="form-group"> 
          <input 
              type="text"
              placeholder='UiB Studentnummer(valgfri)'
              className="form-control"
              value={this.state.studentNr}
              onChange={this.onChangeStudentNr}
              />
        </div>
        <div className="form-group"> 
          <input  type="text"
              required
              placeholder='Epost'
              className="form-control"
              value={this.state.email}
              onChange={this.onChangeEmail}
              />
        </div>
        <div className="form-group">
          <label>Fødselsdato: </label>
          <input 
              required
              type="date" 
              className="form-control"
              value={this.state.birthDate}
              onChange={this.onChangeBirthDate}
              />
        </div>
        <div className="form-group">
          <input 
              required
              type="password" 
              placeholder='Passord'
              className="form-control"
              value={this.state.password}
              onChange={this.onChangePassword}
              />
        </div>
        <div className="form-group">
          <input 
              required
              type="password" 
              placeholder='Bekreft passord'
              className="form-control"
              value={this.state.confirmPassword}
              onChange={this.onChangeConfirmPassword}
              />
        </div>

        <div className="form-group">
          <input type="submit" value="Registrer Bruker" className="btn btn-primary" />
        </div>
        <ToastContainer position='top-center'/>
      </form>
    </div>
    )
  }
}