import axios from 'axios';
import React, {useEffect, useState} from 'react';
import baseURL from '../constants/baseURL';
import { useParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
function ConfirmEmailPage(props){

        const [verified, setVerified] = useState(false)

        useEffect(() => {
                axios.post(baseURL+'/users/confirmEmail', {}, {
                        headers: {
                                'authorization':'Bearer ' + params.accessToken
                        }
                }).then((res) => {
                        console.log('test')
                        console.log('res status', res.status)
                        if(res.status === 200){
                                setVerified(true)
                        }
                }).catch((err) => {
                        console.log(err)
                        if(err.response && err.response.status === 400){
                                console.log('går inn her')
                                toast("Noe feil med bekreftelseslenken din!", toastOptions)
                        }else if(err.response && err.response.status === 403){
                                toast("Lenken er utgått, vi sender ny!", toastOptions)
                        }else{
                                toast("Serverfeil, kontakt support@aguib.no")
                        }
                })
        }, [])

        const toastOptions = {
                position: 'top-center',
                autoClose: false,
                closeOnClick: true
        }

        const params = useParams()
        
        if(verified){
                return(
                        <div>
                                <h1>Eposten din er nå verifisert og du er blitt pålogget!</h1>
                                <ToastContainer position='top-center'/>
                        </div>
                )
        }else{
                return(
                        <div>
                                <h1>Noe feil med verifiseringstoken!</h1>
                                <ToastContainer position='top-center'/>
                        </div>   
                )
        }
        
}
export default ConfirmEmailPage