import React, { Component, useEffect } from 'react';
import {Link} from 'react-router-dom';
import LogButton from '../buttons/LogButton';
import ROLE from '../constants/role'

//Mui
import {AppBar, Toolbar, CssBaseline, Typography} from "@material-ui/core"

function Navbar(props) {

  /*const pages = ['']
  return(
    <>
      <CssBaseline/>
      <AppBar position="relative">
        <Toolbar>

        </Toolbar>
      </AppBar>
    </>
  )*/

  //Nav menu does not update when the user logs in or out, this needs to be fixed
  useEffect(() => {

  }, [props.loggedIn])

  return (
    <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
      <LogButton loggedIn={props.loggedIn} handleLogout={props.handleLogout}/>
      <div className="collpase navbar-collapse">
      <ul className="navbar-nav mr-auto">
        {props.loggedIn ? <Link to="/home" className="nav-link">Hjem</Link> : <Link to="/" className="nav-link">Registrer Bruker</Link>}
        {props.loggedIn ? <Link to="/games/list" className="nav-link">Game Liste</Link> : null}
        {props.loggedIn && props.userInfo ? <Link to='/profile' className="nav-link">{props.userInfo.username}</Link> : null}
        {props.userInfo && props.userInfo.role >= ROLE.DM ? <Link to='/dm-panel' className='nav-link'>DM Panel</Link> : null}
        {props.userInfo && props.userInfo.role >= ROLE.ADMIN ? <Link to='/admin' className='nav-link'>Admin Panel</Link> : null}
      </ul>
      </div>
    </nav>
  );
}

export default Navbar;