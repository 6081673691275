import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import baseURL from "../constants/baseURL"
import TextField from '@mui/material/TextField';
import { toast } from "react-toastify";
import ParticipantBox from "./participant-box.component";
import './game-page.component.css'

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled} from '@mui/material/styles';
import { Button, TextareaAutosize, Typography } from "@material-ui/core";
import Theme from "./theme"

function GamePage(props){
        const params = useParams()
        const navigate = useNavigate()
        const [arrangerUsername, setArrangerUsername] = useState('')
        const [arrangerId, setArrangerId] = useState('')
        const [title, setTitle] = useState('')
        const [description, setDescription] = useState('')
        const [startTime, setStartTime] = useState(null)
        const [endTime, setEndTime] = useState(null)
        const [maxParticipants, setMaxParticipants] = useState(0)
        const [location, setLocation] = useState('TBD')
        const [level, setLevel] = useState(0)
        const [participantIds, setParticipantIds] = useState([])
        const [participants, setParticipants] = useState([])
        const [participantList, setParticipantList] = useState(<h1>empty</h1>)
        const [seeParticipants, setSeeParticipants] = useState(false)

        useEffect(() => {
                axios.get(baseURL+'/game/'+params.id)
                        .then(res => {
                                setArrangerId(res.data.arrangerId)
                                setArrangerUsername(res.data.arrangerUsername)
                                setTitle(res.data.title)
                                setDescription(res.data.description)
                                setStartTime(new Date(res.data.startTime))
                                setEndTime(new Date(res.data.endTime))
                                setMaxParticipants(res.data.maxParticipants)
                                setLocation(res.data.location)
                                setLevel(res.data.level)
                                setParticipantIds(res.data.participants)
                                console.log(props.userInfo)
                                //console.log(props.userInfo.userId)
                        }).catch(err => {
                                //Do something when game can't be found, maybe redirect to game list
                                console.log(err)
                                //navigate('/games/list')
                        })
                
                axios.get(baseURL+'/game/'+params.id+'/participants')
                        .then(res => {
                                setParticipants(res.data)
                                //console.log(res.data)
                        })
        }, [])

        useEffect(() => {
                setParticipantList(participants.map(participant => <ParticipantBox key={participant}data={participant}/>))
        }, [participants])

        const duration = () => {
                return ((endTime-startTime) / (1000 * 60))
        }

        const handleParticipate = () => {
                axios.put(baseURL+'/game/'+params.id+ '/participants'
                ).then((req, res) => {
                        window.location.reload(false)
                }).catch((err) => {
                        toast("Noe gikk galt, kanskje det er fult")
                })
        }

        const handleUnparticipate = () => {
                axios.delete(baseURL+'/game/'+params.id + '/participants/' + props.userInfo.userId
                ).then(() => {
                        window.location.reload(false)
                }).catch((err) => {
                        console.log(err)
                })
        }
        
        const handleEdit = () => {
                navigate('/game/'+params.id+'/edit')
        }

        const Item = styled(Paper)(({ theme }) => ({
                backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                ...theme.typography.body2,
                padding: theme.spacing(1),
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }));


        return(
                <div className='game-div'>
                        <Stack>
                        <Typography variant="h4">
                                        DM: {arrangerUsername}
                                </Typography>
                                <Typography variant="h4">
                                        Tittel: {title}
                                </Typography>
                                <TextareaAutosize
                                        aria-label="Beskrivelse"
                                        disabled
                                        placeholder={description}
                                />
                                <Typography variant="h4">
                                        Start: {startTime ? startTime.toString() : "..."}
                                </Typography>
                                <Typography variant="h4">
                                        Varighet: {duration() ? duration().toString() : '...'} minutter
                                </Typography>
                                <Typography variant="h4">
                                        Sted: {location}
                                </Typography>
                                <Typography variant="h4">
                                        Minimum/Anbefalt level: {level}
                                </Typography>
                                <Typography variant="h4">
                                        Deltakere: {participantIds? participantIds.length + ' / ' + maxParticipants: '...'}
                                </Typography>
                                {props.userInfo && participantIds.includes(props.userInfo.userId) ? <Button variant="contained" onClick={handleUnparticipate}>Meld deg av</Button> : <Button variant="contained" onClick={handleParticipate}>Meld deg på</Button>}
                                {props.userInfo && props.userInfo.userId === arrangerId ? <Button variant="contained" onClick={handleEdit}>Endre</Button> : null}
                                <Typography variant="h4">
                                        Deltakere: {participantIds? participantIds.length + ' / ' + maxParticipants: '...'}
                                </Typography>
                                {participantList}
                        </Stack>
                </div>
        )
}

export default GamePage