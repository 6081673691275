import {Link} from 'react-router-dom';
function AdminPanel(props){
        return(
                <div>
                        <li>
                                <h1>Admin Panel</h1>
                                <ul><Link to="/admin/users">Bruker liste</Link></ul>
                        </li>
                </div>
        )
}

export default AdminPanel