import axios from 'axios';
import e from 'cors';
import { setMilliseconds } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import baseURL from '../constants/baseURL';
function EditGame(props) {
        const navigate = useNavigate()
        const params = useParams()
        const [gameId, setGameId] = useState('')
        const [arrangerId, setArrangerId] = useState('')
        const [title, setTitle] = useState('')
        const [description, setDescription] = useState('')
        const [startTime, setStartTime] = useState('')
        const [endTime, setEndTime] = useState('')
        const [maxParticipants, setMaxParticipants] = useState(0)
        const [location, setLocation] = useState('TBD')
        const [level, setLevel] = useState(0)

        useEffect(() => {
                axios.get(baseURL+'/game/'+params.id)
                        .then(res => {
                                setGameId(res.data._id)
                                setArrangerId(res.data.arrangerId)
                                setTitle(res.data.title)
                                setDescription(res.data.description)
                                setStartTime(res.data.startTime.toString().slice(0, -1))
                                setEndTime(res.data.endTime.toString().slice(0, -1))
                                setMaxParticipants(res.data.maxParticipants)
                                setLocation(res.data.location)
                                setLevel(res.data.level)
                        }).catch(err => {
                                console.log(err)
                        })
        }, [])
        
        const onSubmit = (e) => {
                const game = {
                        userId: props.userInfo.userId,
                        arrangerId: arrangerId,
                        title: title,
                        description: description,
                        startTime: startTime,
                        endTime: endTime,
                        maxParticipants: maxParticipants,
                        location: location,
                        level: level
                }
                console.log("Game: " + game)
                e.preventDefault()
                axios.put(baseURL+'/game/'+params.id, game
                ).then(() => {
                        toast("Game lagret!")
                        navigate('/game/'+params.id)
                }).catch((err) => {
                        toast("Noe gikk galt under lagring av game!")
                        console.log(err)
                })
        }

        const handleDeleteGame = () => {

                axios.delete(baseURL+'/game/'+params.id, { data: {gameId: gameId, arrangerId: arrangerId}})
                        .then(() => {
                                toast('game slettet')
                        }).catch(err => {
                                toast("noe gikk galt")
                                console.log(err)
                        })
        }

        return(
                <div className="create-game-box">
                        <h3>Lag Game</h3>
                        <form onSubmit={onSubmit}>
                                <div className="form-group"> 
                                        <label>Tittel</label>
                                        <input 
                                        required
                                        type="text" 
                                        placeholder='Tittel'
                                        className="form-control"
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Beskrivelse</label>
                                        <input 
                                        required
                                        type="text" 
                                        placeholder='Beskrivelse'
                                        className="form-control"
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Start Tid:</label>
                                        <input 
                                        required
                                        type="datetime-local" 
                                        className="form-control"
                                        value={startTime}
                                        onChange={e => setStartTime(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Slutt Tid:</label>
                                        <input 
                                        required
                                        type="datetime-local" 
                                        className="form-control"
                                        value={endTime}
                                        onChange={e => setEndTime(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Maks Deltakere</label>
                                        <input 
                                        required
                                        type="number" 
                                        placeholder='Maks deltakere'
                                        className="form-control"
                                        value={maxParticipants}
                                        onChange={e => setMaxParticipants(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Sted</label>
                                        <input 
                                        required
                                        type="text" 
                                        placeholder='Sted'
                                        className="form-control"
                                        value={location}
                                        onChange={e => setLocation(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <label>Anbefalt/Minimum level</label>
                                        <input 
                                        required
                                        type="number" 
                                        placeholder='Anbefalt/Minimum level'
                                        className="form-control"
                                        value={level}
                                        onChange={e => setLevel(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                        <input type="submit" value="Lagre" className="btn btn-primary" />
                                        <input type='button' value='Slett game' onClick={handleDeleteGame}/>
                                </div>
                                <ToastContainer position='top-center'/>
                        </form>
                </div>
        )
}

export default EditGame